import { useMediaQuery } from "react-responsive";
import { SimpleContainer } from "../simpleComponents/SimpleContainer";
import { colors } from "../../styles/colors";
import SimpleImage from "../simpleComponents/SimpleImage";
import { images } from "../../assets/images/images";
import { Text24 } from "../styledComponents/texts/Text24";
import ImageButton from "../simpleComponents/ImageButton";
import { MainScreenData } from "../../pages/mainScreen/MainScreenData";
import TertiaryButton from "../styledComponents/buttons/generic/TertiaryButton";
import { Text16 } from "../styledComponents/texts/Text16";
import { buttonSizes } from "../../styles/buttons/buttonSizes";
import { Text20 } from "../styledComponents/texts/Text20";

const { FooterData } = MainScreenData

export default function Footer({ HandleScrollToPages }) {
    const isSmallScreen = useMediaQuery({ maxWidth: 1480 });

    function handleLinksPress(src) {
        if (src === 'Google') {
            window.location.href = 'https://did.li/jfRNf';
        } else {
            window.location.href = 'https://did.li/Wpjrl';
        }
    }

    return (
        <SimpleContainer style={styles.container(isSmallScreen)}>
            {isSmallScreen ? (
                <SimpleContainer style={styles.FooterContainer}>
                    <SimpleImage src={images.IconImages.LightBulb} style={styles.image(isSmallScreen)} />
                    <Text24 style={{ color: colors.white, marginTop: 40 }}>להורדת האפליקציה בחנות האפליקציות -</Text24>
                    <SimpleContainer style={styles.buttonContainer}>
                        <ImageButton
                            image={images.Buttons.AppleDownload}
                            style={{ width: 266, height: 78 }}
                            tintColor={colors.white}
                            onPress={() => handleLinksPress('Apple')}
                        />
                        <ImageButton
                            image={images.Buttons.GoogleDownload}
                            style={{ width: 266, height: 78, marginTop: 8 }}
                            tintColor={colors.white}
                            onPress={() => handleLinksPress('Google')}
                        />
                    </SimpleContainer>
                    <SimpleContainer style={{ width: '100%', justifyContent: 'space-evenly' }}>
                        {[0, 3].map((startIndex) => (
                            <SimpleContainer key={startIndex} style={styles.buttonContainer}>
                                {FooterData.slice(startIndex, startIndex + 3).map((item, index) => (
                                    <TertiaryButton
                                        key={index}
                                        onPress={() => HandleScrollToPages(item.pageName + 'Small')}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Text16 color={colors.white}>{item.text}</Text16>
                                    </TertiaryButton>
                                ))}
                            </SimpleContainer>
                        ))}
                    </SimpleContainer>
                    <SimpleContainer
                        style={{ flexDirection: 'column', alignItems: 'center' }}
                    >
                        <SimpleContainer style={{ flexDirection: 'column', alignItems: 'center' }}>
                            <SimpleImage src={images.Logos.FolloAppWithRights} style={{ width: 310, height: 76 }} />

                            <Text16
                                color={colors.white}
                                style={{ marginTop: 16 }}
                            >
                                בשיתוף שווה פיננסים
                            </Text16>
                        </SimpleContainer>
                        <SimpleContainer style={{ marginTop: 24, flexDirection: 'column', alignItems: 'center' }}>
                            <Text16 color={colors.white}>ליצירת קשר</Text16>
                            <TertiaryButton
                                size={buttonSizes.SMALL}
                                style={{
                                    marginTop: 4, cursor: 'pointer',
                                }}
                                onPress={() => { window.location.href = `tel:+972549340572`; }}
                            >
                                <Text16 color={colors.white}>0549340572</Text16>
                            </TertiaryButton>
                        </SimpleContainer>
                        <SimpleContainer style={{ flexDirection: 'column', alignItems: 'center' }}>
                            <Text16
                                color={colors.white}
                                style={{ marginTop: 24 }}
                            >
                                פנחס 84 רמת גן
                            </Text16>

                            <Text16
                                color={colors.white}
                                style={{ marginTop: 4 }}
                            >
                                5227523
                            </Text16>
                        </SimpleContainer>
                    </SimpleContainer>
                </SimpleContainer>
            ) : (
                <SimpleContainer style={{ flex: 1, justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <SimpleContainer
                        style={{ flexDirection: 'column', alignItems: 'center' }}
                    >
                        <SimpleContainer style={{ flexDirection: 'column', alignItems: 'center' }}>
                            <SimpleImage src={images.Logos.FolloAppWithRights} style={{ width: 310, height: 76 }} />

                            <Text16
                                color={colors.white}
                                style={{ marginTop: 16 }}
                            >
                                בשיתוף שווה פיננסים
                            </Text16>
                        </SimpleContainer>
                        <SimpleContainer style={{ marginTop: 24, flexDirection: 'column', alignItems: 'center' }}>
                            <Text16 color={colors.white}>ליצירת קשר</Text16>
                            <TertiaryButton
                                size={buttonSizes.SMALL}
                                style={{
                                    marginTop: 4, cursor: 'pointer',
                                }}
                                onPress={() => { window.location.href = `tel:+972549340572`; }}
                            >
                                <Text16 color={colors.white}>0549340572</Text16>
                            </TertiaryButton>
                        </SimpleContainer>
                        <SimpleContainer style={{ flexDirection: 'column', alignItems: 'center' }}>
                            <Text16
                                color={colors.white}
                                style={{ marginTop: 24 }}
                            >
                                פנחס 84 רמת גן
                            </Text16>

                            <Text16
                                color={colors.white}
                                style={{ marginTop: 4 }}
                            >
                                5227523
                            </Text16>
                        </SimpleContainer>
                    </SimpleContainer>
                    <SimpleContainer >
                        {[0, 2, 4].map((startIndex) => (
                            <SimpleContainer key={startIndex} style={styles.buttonContainer}>
                                {FooterData.slice(startIndex, startIndex + 2).map((item, index) => (
                                    <TertiaryButton
                                        key={index}
                                        size={buttonSizes.SMALL}
                                        style={{
                                            marginTop: index !== 0 ? 8 : 0, cursor: 'pointer',
                                        }}
                                        onPress={() => HandleScrollToPages(item.pageName)}
                                    >
                                        <Text16 color={colors.white}>{item.text}</Text16>
                                    </TertiaryButton>
                                ))}
                            </SimpleContainer>
                        ))}
                    </SimpleContainer>
                    <SimpleContainer style={styles.FooterContainer}>
                        <Text20 style={{ color: colors.white }}>להורדת האפליקציה בחנות האפליקציות</Text20>
                        <SimpleContainer style={styles.buttonContainerBig}>
                            <ImageButton
                                image={images.Buttons.AppleDownload}
                                style={{ width: 200, height: 60, marginLeft: 8 }}
                                tintColor={colors.white}
                                onPress={() => handleLinksPress('Apple')}
                            />
                            <ImageButton
                                image={images.Buttons.GoogleDownload}
                                style={{ width: 200, height: 60, marginRight: 8 }}
                                tintColor={colors.white}
                                onPress={() => handleLinksPress('Google')}
                            />
                        </SimpleContainer>
                    </SimpleContainer>
                    <SimpleImage src={images.IconImages.LightBulb} style={{ width: 272, height: 385, top: '-80px', position: 'relative', }} />
                </SimpleContainer>
            )
            }
        </SimpleContainer >
    );
}

const styles = {
    container: (isSmallScreen) => ({
        paddingLeft: isSmallScreen ? 20 : 0,
        paddingRight: isSmallScreen ? 20 : 0,
        paddingTop: isSmallScreen ? 40 : 20, // Adjust based on the height of the header
        paddingBottom: isSmallScreen ? 40 : 20,
        background: colors.primary,
        width: '100%'
    }),
    FooterContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
    },
    image: (isSmallScreen) => ({
        width: 142,
        height: 200,
        marginTop: isSmallScreen ? -72 : 0, // Apply negative margin-top for small screens
        justifyContent: 'center'
    }),
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 24,
        alignItems: 'flex-start',
    },
    buttonContainerBig: {
        display: 'flex',
        flex: 1,
        alignItems: 'flex-start',
        marginTop: 24
    },
}